import { Container, Typography, Box } from '@mui/material';

import './privacy.page.css';

function PrivacyPage() {
  return (
    <Box id="privacy-page" className="content withVerticalPadding">
      <Container maxWidth="lg">
        <Typography variant="h4" component={'h1'} gutterBottom>
          Datenschutzerklärung
        </Typography>

        <Typography variant="h5" component={'h2'} gutterBottom>
          1. Allgemeine Hinweise und Pflichtinformationen
        </Typography>

        <Typography variant="body1" gutterBottom>
          Wir freuen uns über Ihr Interesse an unserem ShortLink-Dienst. Der
          Schutz Ihrer persönlichen Daten ist uns ein wichtiges Anliegen. In
          dieser Datenschutzerklärung informieren wir Sie umfassend über die
          Verarbeitung Ihrer personenbezogenen Daten auf unserer Webseite und
          über die Ihnen zustehenden Rechte.
        </Typography>

        <Typography variant="h5" component={'h2'} gutterBottom>
          2. Verantwortliche Stelle
        </Typography>

        <Typography variant="subtitle2" component={'p'}>
          Verantwortlich für die Datenverarbeitung auf dieser Website ist:
        </Typography>

        <Typography variant="body1" component={'p'} gutterBottom>
          Christopher Kellner
          <br />
          Linkelstraße 34
          <br />
          04159 Leipzig
          <br />
          Deutschland
          <br />
          E-Mail: office@linkaro.de
        </Typography>

        <Typography variant="body1" component={'p'} gutterBottom>
          Bei Fragen zum Datenschutz können Sie sich jederzeit an uns wenden.
        </Typography>

        <Typography variant="h5" component={'h2'} gutterBottom>
          3. Datenerfassung auf unserer Website
        </Typography>

        <Typography variant="h6" component={'h3'} gutterBottom>
          a. Lokaler Browserspeicher zur temporären Speicherung von generierten
          ShortLinks
        </Typography>

        <Typography variant="body1" component={'p'} gutterBottom>
          Unsere Webseite nutzt den LocalStorage Ihres Browsers, um von Ihnen
          erstellte ShortLinks temporär zu speichern und Ihnen eine verbesserte
          Benutzererfahrung zu bieten. Diese Daten verbleiben auf Ihrem Endgerät
          und können von Ihnen jederzeit gelöscht werden. Eine Übertragung
          dieser Daten an unseren Server oder Dritte findet nicht statt.
        </Typography>

        <Typography variant="subtitle2" component="p" gutterBottom>
          Rechtsgrundlage
        </Typography>

        <Typography variant="body1" component={'p'} gutterBottom>
          Die Verarbeitung erfolgt auf Grundlage unseres berechtigten Interesses
          gemäß Art. 6 Abs. 1 lit. f DSGVO, die Benutzerfreundlichkeit unseres
          Dienstes zu optimieren.
        </Typography>

        <Typography variant="h6" component={'h3'} gutterBottom>
          b. Newsletter-Abonnement
        </Typography>

        <Typography variant="body1" component={'p'} gutterBottom>
          Wenn Sie sich für unseren Newsletter anmelden, verwenden wir Ihre
          E-Mail-Adresse, um Ihnen regelmäßig Informationen zu unseren Diensten
          zuzusenden.
        </Typography>

        <Typography variant="subtitle2" component="p" gutterBottom>
          Rechtsgrundlage
        </Typography>

        <Typography variant="body1" component={'p'} gutterBottom>
          Die Verarbeitung Ihrer Daten erfolgt auf Grundlage Ihrer Einwilligung
          gemäß Art. 6 Abs. 1 lit. a DSGVO.
        </Typography>

        <Typography variant="subtitle2" component="p" gutterBottom>
          Speicherdauer
        </Typography>

        <Typography variant="body1" component={'p'} gutterBottom>
          Ihre Daten werden für die Dauer des Newsletter-Abonnements
          gespeichert.
        </Typography>

        <Typography variant="subtitle2" component="p" gutterBottom>
          Widerruf
        </Typography>

        <Typography variant="body1" component={'p'} gutterBottom>
          Sie können Ihre Einwilligung zur Speicherung der Daten und deren
          Nutzung zum Versand des Newsletters jederzeit widerrufen, z.B. über
          den "Abmelden"-Link im Newsletter. Ihre Daten werden dann umgehend aus
          unserem System gelöscht.
        </Typography>

        <Typography variant="h5" component={'h2'} gutterBottom>
          4. Ihre Rechte als betroffene Person
        </Typography>

        <Typography variant="body1" component={'p'} gutterBottom>
          Sie haben im Rahmen der geltenden gesetzlichen Bestimmungen jederzeit
          das Recht auf:
        </Typography>

        <ul>
          <li>
            Auskunft über Ihre bei uns gespeicherten personenbezogenen Daten
            (Art. 15 DSGVO).
          </li>
          <li>
            Berichtigung unrichtiger oder Vervollständigung unvollständiger
            Daten (Art. 16 DSGVO).
          </li>
          <li>Löschung Ihrer bei uns gespeicherten Daten (Art. 17 DSGVO).</li>
          <li>Einschränkung der Verarbeitung Ihrer Daten (Art. 18 DSGVO).</li>
          <li>Datenübertragbarkeit (Art. 20 DSGVO).</li>
          <li>
            Widerspruch gegen die Verarbeitung Ihrer Daten (Art. 21 DSGVO).
          </li>
          <li>
            Widerruf Ihrer erteilten Einwilligung mit Wirkung für die Zukunft
            (Art. 7 Abs. 3 DSGVO).
          </li>
          <li>Beschwerde bei einer Aufsichtsbehörde (Art. 77 DSGVO).</li>
        </ul>

        <Typography variant="body1" component={'p'} gutterBottom>
          Zur Ausübung Ihrer Rechte können Sie sich jederzeit an uns wenden.
        </Typography>

        <Typography variant="h5" component={'h2'} gutterBottom>
          5. Datensicherheit
        </Typography>

        <Typography variant="body1" component={'p'} gutterBottom>
          Wir verwenden geeignete technische und organisatorische
          Sicherheitsmaßnahmen, um Ihre Daten gegen Manipulation, Verlust,
          Zerstörung oder gegen den unbefugten Zugriff Dritter zu schützen.
          Unsere Sicherheitsmaßnahmen werden entsprechend der technologischen
          Entwicklung fortlaufend verbessert.
        </Typography>

        <Typography variant="h5" component={'h2'} gutterBottom>
          6. Änderungen dieser Datenschutzerklärung
        </Typography>

        <Typography variant="body1" component={'p'} gutterBottom>
          Wir behalten uns vor, diese Datenschutzerklärung bei Bedarf
          anzupassen, um sie an rechtliche oder technische Änderungen
          anzupassen. Die aktuelle Datenschutzerklärung kann jederzeit auf
          unserer Website abgerufen werden. Bei wesentlichen Änderungen
          informieren wir Sie gegebenenfalls per E-Mail oder durch einen Hinweis
          auf unserer Webseite.
        </Typography>
      </Container>
    </Box>
  );
}

export default PrivacyPage;
