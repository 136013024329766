import { initClient } from './utils/init-client.js';
export const createLinksConnector = ({ apiKey, baseUrl, }) => {
    const getAllLinks = async (includeCreator = false) => {
        const client = initClient(baseUrl, apiKey);
        const { data } = await client.GET('/links', {
            params: { query: { includeCreator } },
        });
        return data;
    };
    const getLinkById = async (id, includeCreator = false) => {
        const client = initClient(baseUrl, apiKey);
        const { data } = await client.GET('/links/{id}', {
            params: { path: { id }, query: { includeCreator } },
        });
        return data;
    };
    const getLinkByShortUrl = async (shortUrl, includeCreator = false) => {
        const client = initClient(baseUrl, apiKey);
        const { data } = await client.GET('/links', {
            params: { query: { shortUrl, includeCreator } },
        });
        const singleItem = data?.length === 1 ? data[0] : undefined;
        return singleItem;
    };
    const addLink = async (link) => {
        const client = initClient(baseUrl, apiKey);
        const { data, error } = await client.POST('/links', {
            body: { url: link },
        });
        if (error !== undefined) {
            throw new Error('Could not add a new link');
        }
        return data;
    };
    return {
        getAllLinks,
        getLinkById,
        getLinkByShortUrl,
        addLink,
    };
};
// export const updateLink = async (baseUrl: string, id: string, link: string) => {
//   const client = createClient<paths>({ baseUrl });
//   const { data } = await client.PUT('/links/{id}', {
//     params: { path: { id } },
//     body: { url: link },
//   });
//   return data;
// };
// export const deleteLink = async (baseUrl: string, id: string) => {
//   const client = createClient<paths>({ baseUrl });
//   const { data } = await client.DELETE('/links/{id}', {
//     params: { path: { id } },
//   });
//   return data;
// };
