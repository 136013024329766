import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { Box, Link, List, ListItem } from '@mui/material';
import { Outlet, Link as RouterLink } from 'react-router-dom';
import DatasetLinkedIcon from '@mui/icons-material/DatasetLinked';

import './root.page.css';
import { NewsletterBox } from './components/newsletter-box';

function RootPage({ children }: { children?: JSX.Element }) {
  return (
    <>
      <AppBar position="fixed" elevation={0} id="header">
        <Toolbar disableGutters>
          <Container maxWidth="lg">
            <Link
              component={RouterLink}
              to="/"
              variant="h6"
              underline="none"
              className="contrastText"
            >
              <DatasetLinkedIcon />
              <span>Linkaro</span>
            </Link>
          </Container>
        </Toolbar>
      </AppBar>

      {/* See https://mui.com/material-ui/react-app-bar/#fixed-placement for other workarounds - occurs because of the fixed position */}
      <Toolbar />

      {children ?? <Outlet />}

      <Box id="footer" className="withVerticalPadding">
        <Container maxWidth="lg" className="footer-container">
          <Box>
            <NewsletterBox />
          </Box>
          <Box>
            <Typography variant="h6" component="p" color="primary.dark">
              Legal
            </Typography>
            <List dense>
              <ListItem>
                <Link
                  component={RouterLink}
                  to="/imprint"
                  underline="hover"
                  color="textPrimary"
                >
                  Imprint
                </Link>
              </ListItem>
              <ListItem>
                <Link
                  component={RouterLink}
                  to="/privacy"
                  underline="hover"
                  color="textPrimary"
                >
                  Privacy Policy
                </Link>
              </ListItem>
            </List>
          </Box>
        </Container>
      </Box>
    </>
  );
}

export default RootPage;
