import { Box, Container, Typography } from '@mui/material';

import './imprint.page.css';

function ImprintPage() {
  return (
    <Box id="imprint-page" className="content withVerticalPadding">
      <Container maxWidth="lg">
        <Typography variant="h4" component={'h1'} gutterBottom>
          Impressum
        </Typography>

        <Typography variant="h6" component={'h2'} gutterBottom>
          Angaben gemäß § 5 TMG
        </Typography>

        <Typography variant="subtitle2" component={'h3'}>
          Name des Webseitenbetreibers
        </Typography>

        <Typography variant="body1" gutterBottom>
          Christopher Kellner
        </Typography>

        <Typography variant="subtitle2" component={'h3'}>
          Anschrift
        </Typography>

        <Typography variant="body1" gutterBottom>
          Linkelstraße 34
          <br />
          04159 Leipzig
          <br />
          Deutschland
        </Typography>

        <Typography variant="subtitle2" component={'h3'}>
          Kontakt
        </Typography>

        <Typography variant="body1" gutterBottom>
          E-Mail: office@linkaro.de
        </Typography>

        <Typography variant="h6" component={'h2'} gutterBottom>
          Verantwortlich für den Inhalt nach § 55 Abs. 2 RStV
        </Typography>

        <Typography variant="body1" gutterBottom>
          Christopher Kellner
          <br />
          Linkelstraße 34
          <br />
          04159 Leipzig
          <br />
          Deutschland
        </Typography>
      </Container>
    </Box>
  );
}

export default ImprintPage;
