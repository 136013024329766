import { initClient } from './utils/init-client.js';
export const createNewsletterConnector = ({ apiKey, baseUrl, }) => {
    const addNewsletter = async (email) => {
        const client = initClient(baseUrl, apiKey);
        const { data } = await client.POST('/newsletter', {
            body: { email: email },
        });
        return data;
    };
    return {
        addNewsletter,
    };
};
