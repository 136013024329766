import { Box, Button, Link, TextField, Typography } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import './newsletter-box.css';
import { useState } from 'react';
import { api } from '../utils/api';
import { Favorite } from '@mui/icons-material';

export function NewsletterBox() {
  const [email, setEmail] = useState('');
  const [isSubmitted, setSubmitted] = useState<boolean>(false);
  const [emailError, setEmailError] = useState<string | null>(null);
  const [isTouched, setTouched] = useState<boolean>(false);

  const validateEmail = (value: string) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (value && !emailRegex.test(value)) {
      setEmailError('Please enter a valid email address');
    } else {
      setEmailError(null);
    }
  };

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;

    setEmail(value);

    if (value === '') {
      setTouched(false);
    }

    validateEmail(value);
  };

  const handleEmailBlur = () => {
    if (email !== '') {
      setTouched(true);
    }
  };

  const registerMail = async () => {
    if (emailError) {
      return;
    }

    await api
      .addNewsletter(email)
      .then(() => {
        setSubmitted(true);
      })
      .catch(() => {
        setEmailError('Sorry something went wrong :(');
      });
  };

  return (
    <Box className="newsletter-box-container">
      {isSubmitted ? (
        <Box className="newsletter-box-thanks">
          <Typography variant="h6" component="p" color="primary.dark">
            Newsletter
          </Typography>
          <Box className="newsletter-box-thanks-header">
            <Favorite fontSize="large" color="error" />
            <Typography variant="h6" component="p">
              Welcome to the club!
            </Typography>
          </Box>
          <Typography variant="caption" component="p">
            We value your{' '}
            <Link
              component={RouterLink}
              to="/privacy"
              underline="hover"
              color="primary.dark"
            >
              Privacy
            </Link>
            . To prevent unwanted spam, you need to <b>confirm</b> that
            subsciption in the mail we sent you.
          </Typography>
        </Box>
      ) : (
        <>
          <Box>
            <Typography variant="h6" component="p" color="primary.dark">
              Newsletter
            </Typography>
            <Typography variant="body2" component="p">
              Want more? Join our newsletter and stay up to date!
            </Typography>
          </Box>
          <Box className="newsletter-box-form">
            <TextField
              id="outlined-basic"
              placeholder="Enter your email"
              variant="outlined"
              className="newsletter-box-input"
              size="small"
              onChange={handleEmailChange}
              onBlur={handleEmailBlur}
              value={email}
              error={emailError !== null && isTouched}
              helperText={isTouched ? emailError : undefined}
            />
            <Button
              variant="contained"
              onClick={registerMail}
              className="newsletter-box-button"
              disabled={emailError !== null || email.length === 0}
            >
              I'm in!
            </Button>
          </Box>
          <Typography variant="caption" component="p">
            By subscribing, you agree to our&nbsp;
            <Link
              component={RouterLink}
              to="/privacy"
              underline="hover"
              color="primary.dark"
            >
              Privacy Policy
            </Link>
          </Typography>
        </>
      )}
    </Box>
  );
}
