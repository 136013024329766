/**
 * Validates if the given string is a URL with:
 * - A required protocol (http:// or https://)
 * - A valid domain name (no IP addresses, including IPv4 and IPv6)
 * - No port allowed
 * - Optional path, query parameters, and fragments
 *
 * @param {string} url - The URL string to validate.
 * @returns {boolean} - Returns true if the URL is valid, false otherwise.
 *
 * @example
 * isValidUrl("https://example.com");
 * // Returns true
 *
 * @example
 * isValidUrl("http://example.com/path/to/page");
 * // Returns true
 *
 * @example
 * isValidUrl("https://example.com/my-product?ref=123#details");
 * // Returns true
 *
 * @example
 * isValidUrl("http://192.168.1.1");
 * // Returns false (IPv4 address not allowed)
 *
 * @example
 * isValidUrl("https://[2001:db8::1]");
 * // Returns false (IPv6 address not allowed)
 *
 * @example
 * isValidUrl("https://example.com:8080");
 * // Returns false (Port not allowed)
 */
export function isValidUrl(url: string): boolean {
  const urlPattern = new RegExp(
    '^(https?:\\/\\/)' + // Erforderliches Protokoll
      '(([a-zA-Z\\d]([a-zA-Z\\d-]*[a-zA-Z\\d])*)\\.)+[a-zA-Z]{2,}' + // Domainname (keine IP-Adressen)
      '(\\/[-a-zA-Z\\d%_.~+]*)*' + // Optionaler Pfad
      '(\\?[;&a-zA-Z\\d%_.~+=-]*)?' + // Optionale Query-Parameter
      '(\\#[-a-zA-Z\\d_]*)?$', // Optionales Fragment
    'i',
  );

  // Prüft, ob die URL die Regex erfüllt und keine IP-Adresse enthält
  if (!urlPattern.test(url)) {
    return false;
  }

  // Zusätzliche Überprüfung auf IP-Adressen (IPv4 und IPv6)
  try {
    const hostname = new URL(url).hostname;
    // Prüft auf IPv4 (z.B. 192.168.1.1)
    const ipv4Pattern = /^\d{1,3}(\.\d{1,3}){3}$/;
    // Prüft auf IPv6 (z.B. 2001:db8::1)
    const ipv6Pattern = /^\[?[a-fA-F\d:]+\]?$/;

    if (ipv4Pattern.test(hostname) || ipv6Pattern.test(hostname)) {
      return false;
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
  } catch (error: unknown) {
    return false;
  }

  return true;
}
