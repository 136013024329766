import { Box, Container } from '@mui/material';

function ErrorPage() {
  return (
    <Box className="content withVerticalPadding">
      <Container maxWidth="lg">
        <h1>Not found</h1>
        <p>The page you are looking for does not exist</p>
      </Container>
    </Box>
  );
}

export default ErrorPage;
