import { useState } from 'react';
import {
  Box,
  Chip,
  Divider,
  Link,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Paper,
  Snackbar,
  SnackbarCloseReason,
  TextField,
} from '@mui/material';
import LinkIcon from '@mui/icons-material/Link';
import ScheduleIcon from '@mui/icons-material/Schedule';
import SendIcon from '@mui/icons-material/Send';
import { formatDistanceToNow } from 'date-fns';

import './shortener.css';
import { api } from '../utils/api';
import { isValidUrl } from '../utils/is-valid-url';
import { LoadingButton } from '@mui/lab';

type ShortLink = {
  id: string;
  targetUrl: string;
  shortHostName: string;
  shortUrl: string;
  createdAt: string;
  expiresAt: string;
};

type UrlError = {
  message: string;
  type: 'api' | 'user';
};

export function Shortener() {
  const [url, setUrl] = useState(''); // Zustand für das URL-Eingabefeld
  const [shortLink, setShortLink] = useState<ShortLink | undefined>(); // Zustand für das Ergebnis
  const [isSnackbarOpen, setSnackbarOpen] = useState<boolean>(false);
  const [urlError, setUrlError] = useState<UrlError | null>(null);
  const [isTouched, setTouched] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const validateLink = (value: string) => {
    if (value && !isValidUrl(value)) {
      setUrlError({ message: 'Please enter a valid URL', type: 'user' });
    } else {
      setUrlError(null);
    }
  };

  const handleShorten = async () => {
    try {
      setLoading(true);

      const apiRequest = api.addLink(url);

      const delay = new Promise((resolve) => setTimeout(resolve, 1000));

      const result = await Promise.all([apiRequest, delay]).then(
        ([data]) => data,
      );

      setShortLink(result);
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
    } catch (e: unknown) {
      setUrlError({
        message: 'Ooops, something went wrong :( Please try again.',
        type: 'api',
      });
    } finally {
      setLoading(false);
    }
  };

  const handleCopyToClipboard = async () => {
    if (shortLink?.shortUrl) {
      try {
        await navigator.clipboard.writeText(shortLink.shortUrl);
        setSnackbarOpen(true);
      } catch (e) {
        console.error('Failed to copy: ', e);
      }
    }
  };

  const handleUrlChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;

    setUrl(value);

    if (value === '') {
      setTouched(false);
    }

    validateLink(value);
  };

  const handleClose = (
    _event: React.SyntheticEvent | Event,
    reason?: SnackbarCloseReason,
  ) => {
    if (reason === 'clickaway') {
      return;
    }

    setSnackbarOpen(false);
  };

  const handleUrlBlur = () => {
    if (url !== '') {
      setTouched(true);
    }
  };

  const link =
    shortLink !== undefined
      ? `${shortLink.shortHostName}/${shortLink.shortUrl}`
      : '';

  return (
    <Paper elevation={3} className="shortener-container">
      <Box className="form">
        <TextField
          className="field"
          label="Your very long, unreadable, .. URL"
          variant="standard"
          value={url}
          onChange={handleUrlChange}
          onBlur={handleUrlBlur}
          error={urlError !== null && isTouched}
          helperText={isTouched ? urlError?.message : undefined}
        />
        <LoadingButton
          loading={loading}
          loadingPosition="center"
          endIcon={<SendIcon />}
          variant="contained"
          onClick={handleShorten}
          disabled={urlError?.type === 'user' || url.length === 0}
        >
          Shorten
        </LoadingButton>
      </Box>

      {shortLink && (
        <Box>
          <Divider>
            <Chip label="Your Link" size="small" />
          </Divider>
          <List dense>
            <ListItem>
              <ListItemIcon
                onClick={handleCopyToClipboard}
                className="hoverPointer"
              >
                <LinkIcon />
              </ListItemIcon>
              <ListItemText primary={<Link href={link}>{link}</Link>} />
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <ScheduleIcon />
              </ListItemIcon>
              <ListItemText
                primary={formatDistanceToNow(new Date(shortLink.expiresAt))}
                secondary="left before your link expires"
              />
            </ListItem>
          </List>
        </Box>
      )}

      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={isSnackbarOpen}
        autoHideDuration={2000}
        onClose={handleClose}
        message="Link copied to Clipboard!"
      />
    </Paper>
  );
}
