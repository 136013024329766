import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { CssBaseline } from '@mui/material';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import type {} from '@mui/lab/themeAugmentation';

import './index.css';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

import RootPage from './root.page.tsx';
import ErrorPage from './pages/error.page.tsx';
import ImprintPage from './pages/imprint.page.tsx';
import MainPage from './pages/main.page.tsx';
import PrivacyPage from './pages/privacy.page.tsx';

const theme = createTheme({
  cssVariables: true,
});

const router = createBrowserRouter([
  {
    path: '/',
    element: <RootPage />,
    errorElement: (
      <RootPage>
        <ErrorPage />
      </RootPage>
    ),
    children: [
      {
        index: true,
        element: <MainPage />,
      },
      {
        path: '/imprint',
        element: <ImprintPage />,
      },
      {
        path: '/privacy',
        element: <PrivacyPage />,
      },
    ].map((route) => ({ errorElement: <ErrorPage />, ...route })),
  },
]);

createRoot(document.getElementById('root')!).render(
  <StrictMode>
    <ThemeProvider theme={theme}>
      <RouterProvider router={router} />
      <CssBaseline />
    </ThemeProvider>
  </StrictMode>,
);
