import { Container, Typography, Box } from '@mui/material';
import { Shortener } from '../components/shortener';

import './main.page.css';

function MainPage() {
  return (
    <Box id="main-page" className="content withVerticalPadding">
      <Container maxWidth="md">
        <Typography variant="body1" component={'p'} className="contrastText">
          Paste your link below and let the magic happen
        </Typography>
        <Shortener></Shortener>
      </Container>
    </Box>
  );
}

export default MainPage;
